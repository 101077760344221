import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: " bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex gap-x-4" }
const _hoisted_3 = {
  key: 1,
  class: "mb-16 overflow-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Select, {
            class: "w-56",
            onOpenSelect: _ctx.onOpenSelectStatus,
            onCloseSelect: _ctx.onCloseSelectStatus,
            isOpenMenu: _ctx.isOpenSelectStatus,
            value: _ctx.filterStatus,
            onChooseSelect: _ctx.onSelectStatus,
            dataSelect: _ctx.listStatus,
            isDefaultBlack: ""
          }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "dataSelect"])
        ]),
        (_ctx.isCreateAble)
          ? (_openBlock(), _createBlock(_component_lp_button, {
              key: 0,
              title: "Buat Program",
              textColor: "white",
              onClick: _ctx.goToCreate
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.dataProgressiveCommission.length === 0 && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 0,
          data: "data On Process STT"
        }))
      : (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode(_component_TableV2, {
            class: "my-2",
            columns: _ctx.columns,
            data: _ctx.dataProgressiveCommission,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            onRequest: _ctx.getConfigurationList,
            onClick: _ctx.goToDetail,
            isDetailAble: _ctx.isDetailAble,
            isPinnedScrollBottom: "",
            paginationStyle: "v3"
          }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "onClick", "isDetailAble"])
        ]))
  ]))
}