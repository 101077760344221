
import { Vue, Options } from "vue-class-component";
import { ProgressiveCommissionConfigurationController } from "@/app/ui/controllers/ProgressiveCommissionConfigurationController";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import {
  checkRolePermission,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import ProgressiveCommissionConfigurationMilestone from "./progressive-commission-configuration-milestone.vue";
import ProgressiveCommissionConfigurationProgram from "./progressive-commission-configuration-program.vue";
import ProgressiveCommissionConfigurationApplied from "./progressive-commission-configuration-applied.vue";
import ProgressiveCommissionConfigurationCalculation from "./progressive-commission-configuration-calculation.vue";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import { PROGRESSIVE_COMMISSION_CONFIG } from "@/app/infrastructures/misc/RolePermission";
import startCase from "lodash/startCase";

@Options({
  components: {
    CreateUpdate,
    DetailLayout,
    Title,
    ProgressiveCommissionConfigurationMilestone,
    ProgressiveCommissionConfigurationProgram,
    ProgressiveCommissionConfigurationApplied,
    ProgressiveCommissionConfigurationCalculation
  }
})
export default class ProgressiveCommissionConfigurationDetail extends Vue {
  mounted() {
    this.fetchDetailData();
  }

  get id(): any {
    return this.$route.params.id;
  }

  goBack() {
    this.$router.push("/tarif/commission-configuration/progressive-commisson");
  }

  get isEditAble() {
    return (
      checkRolePermission(PROGRESSIVE_COMMISSION_CONFIG.EDIT) &&
      this.detailData.status === "waiting"
    );
  }
  goToEdit() {
    this.$router.push(
      `/tarif/commission-configuration/progressive-commisson/${this.id}/edit`
    );
  }

  get detailData() {
    return ProgressiveCommissionConfigurationController.progressiveCommissionDetail;
  }

  fetchDetailData() {
    ProgressiveCommissionConfigurationController.getProgressiveCommissionDetail(
      this.id
    );
  }

  onChipStatus(status: string) {
    let chip = "";
    switch (status) {
      case "waiting":
        chip =
          "bg-gray-lp-200 text-black-lp-300 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "expired":
        chip =
          "bg-red-lp-600 text-red-lp-500 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "active":
        chip =
          "bg-green-lp-300 text-green-lp-200 rounded-full px-2 text-12px xxl:text-14px";
        break;
      default:
        break;
    }
    return chip;
  }

  formatDate(date: string) {
    return formatDate(date);
  }

  startCase(str: string) {
    return startCase(str);
  }

  get isLoading() {
    return ProgressiveCommissionConfigurationController.isLoading;
  }

  get errorCause() {
    return ProgressiveCommissionConfigurationController.errorCause;
  }
}
