
import {
  checkRolePermission,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { Vue, Options } from "vue-class-component";
import { PROGRESSIVE_COMMISSION_CONFIG } from "@/app/infrastructures/misc/RolePermission";
import { ProgressiveCommissionConfigurationController } from "@/app/ui/controllers/ProgressiveCommissionConfigurationController";
import { ProgressiveCommissionListRequest } from "@/data/payload/api/ProgressiveCommissionConfigurationApiRequest";

@Options({})
export default class ProgressiveCommissionConfigurationList extends Vue {
  mounted() {
    this.getConfigurationList();
  }

  getConfigurationList() {
    return ProgressiveCommissionConfigurationController.getProgressiveCommissionList(
      new ProgressiveCommissionListRequest({
        status: this.filterStatus,
        page: this.pagination.page,
        limit: this.pagination.limit,
        isTotalData: true
      })
    );
  }
  get dataProgressiveCommission() {
    return ProgressiveCommissionConfigurationController
      .progressiveCommissionList.data;
  }
  get pagination() {
    return ProgressiveCommissionConfigurationController
      .progressiveCommissionList.pagination;
  }

  // Table
  columns = [
    {
      name: "ID Program",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: any) => {
        return `<span class='text-left flex justify-center text-black-lp-300'>${item.cpcId}</span>`;
      }
    },
    {
      name: "Tanggal Berlaku",
      styleHead: "w-6/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${formatDateWithoutTime(
          item.cpcStartDate
        )} - ${formatDateWithoutTime(item.cpcEndDate)}</span>`;
      }
    },
    {
      name: "Berlaku Untuk",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300"> ALL ${item.cpcAppliedTo.toUpperCase()}</span>`;
      }
    },
    {
      name: "Digunakan Untuk",
      styleHead: "w-3/12 text-left",
      render: (item: any) => {
        return `<span class="text-black-lp-300"> 
        ${
          item.cpcBookingSource.toLowerCase() === "shipment"
            ? item.cpcShipmentPrefix.split(",").join(", ")
            : item.cpcBookingSource
        }</span>`;
      }
    },
    {
      name: "Tanggal Dibuat",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${formatDateWithoutTime(
          item.cpcCreatedAt
        )}</div>
        <div class="capitalize text-gray-lp-500">${item.cpcCreatedName}</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `
          <div class="flex">
        <div
          class="rounded-full px-2 py-0 text-center ${
            item.cpcStatus.toLowerCase() === "active"
              ? "bg-green-lp-300 text-green-lp-200"
              : item.cpcStatus.toLowerCase() === "waiting"
              ? "bg-gray-lp-1100 text-gray-lp-300"
              : "bg-red-lp-600 text-red-lp-500"
          }"
        ><span class="capitalize">${item.cpcStatus.toLowerCase()}</span></div></div>`;
      }
    }
  ];

  get listStatus() {
    return [
      {
        name: "Pilih Status",
        value: ""
      },
      {
        name: "Active",
        value: "active"
      },
      {
        name: "Waiting",
        value: "waiting"
      },
      {
        name: "Expired",
        value: "expired"
      }
    ];
  }
  isOpenSelectStatus = false;
  filterStatus = "";

  onOpenSelectStatus() {
    this.isOpenSelectStatus = true;
  }
  onCloseSelectStatus() {
    this.isOpenSelectStatus = false;
  }
  onSelectStatus(name: string, value: string) {
    this.filterStatus = value;
    this.onCloseSelectStatus();
    this.getConfigurationList();
  }

  get isLoading() {
    return ProgressiveCommissionConfigurationController.isLoading;
  }

  get isCreateAble() {
    return checkRolePermission(PROGRESSIVE_COMMISSION_CONFIG.CREATE);
  }
  goToCreate() {
    this.$router.push(
      `/tarif/commission-configuration/progressive-commisson/add`
    );
  }

  get isDetailAble() {
    return checkRolePermission(PROGRESSIVE_COMMISSION_CONFIG.DETAIL);
  }
  goToDetail(item: any) {
    this.$router.push(
      `/tarif/commission-configuration/progressive-commisson/${item.cpcId}`
    );
  }
}
