
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { formatPriceRP } from "@/app/infrastructures/misc/Utils";

class Props {
  milestones = prop<any[]>({
    default: [],
    type: Array
  });
}

@Options({
  components: {
    Title
  }
})
export default class ProgressiveCommissionConfigurationMilestone extends Vue.with(
  Props
) {
  columns = [
    {
      name: "Id",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class='flex text-black-lp-300'>${item.id}</span>`;
      }
    },
    {
      name: "Batas Akhir Milestone",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${formatPriceRP(
          item.limit
        )}</div>`;
      }
    },
    {
      name: "Tambahan Komisi",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.commission}%</div>`;
      }
    }
  ];
}
