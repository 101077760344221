import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pr-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Title, { label: "Milestone" }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_TableV2, {
        "border-top": false,
        "border-bottom": false,
        "hide-pagination": true,
        columns: _ctx.columns,
        data: _ctx.milestones,
        isNoPaddingBottom: true
      }, null, 8, ["columns", "data"])
    ])
  ]))
}