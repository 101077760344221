
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import startCase from "lodash/startCase";

class Props {
  detailData = prop<any>({
    default: {},
    type: Object
  });
}

@Options({
  components: {
    Title
  }
})
export default class ProgressiveCommissionConfigurationAppliedFor extends Vue.with(
  Props
) {
  startCase(str: string) {
    return startCase(str);
  }

  get programPrefix() {
    return this.detailData.programPrefix.join(", ");
  }
}
