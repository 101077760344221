
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import startCase from "lodash/startCase";

class Props {
  detailData = prop<any>({
    default: [],
    type: Array
  });
}

@Options({
  components: {
    Title
  }
})
export default class ProgressiveCommissionConfigurationCalculation extends Vue.with(
  Props
) {
  get parameterCalculation() {
    const items = this.detailData.parameterCalculation?.map((item: string) => {
      return startCase(item);
    });

    return items?.join(", ");
  }
}
