import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "flex gap-x-6" }
const _hoisted_3 = { class: "flex gap-x-6" }
const _hoisted_4 = { class: "flex gap-x-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        class: "w-full",
        label: "ID Program",
        value: _ctx.detailData.programId
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Tanggal Mulai",
        value: _ctx.startDate
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Tanggal Berakhir",
        value: _ctx.endDate
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode(_component_DataWrapper, {
        class: "w-full",
        label: "Berlaku Untuk",
        value: _ctx.detailData.programApplied
      }, null, 8, ["value"])
    ])
  ]))
}