import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "pl-5" }
const _hoisted_3 = {
  key: 0,
  class: "mt-1 mb-5"
}
const _hoisted_4 = { class: "\n            font-semibold \n            text-black-lp-300 text-12px \n            xxl:text-14px" }
const _hoisted_5 = { class: "text-gray-lp-300 text-12px xxl:text-14px pt-1" }
const _hoisted_6 = {
  key: 1,
  class: "\n            mt-1\n            mb-5\n            font-semibold\n            text-black-lp-300 text-12px\n            xxl:text-14px\n          "
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, { label: "Digunakan Untuk" }),
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.detailData.programUsedFor, (source, index) => {
        return (_openBlock(), _createBlock("div", { key: index }, [
          (source === 'shipment')
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                _createVNode("div", _hoisted_4, _toDisplayString(_ctx.startCase(source)), 1),
                _createVNode("div", _hoisted_5, _toDisplayString(_ctx.programPrefix), 1)
              ]))
            : (_openBlock(), _createBlock("div", _hoisted_6, _toDisplayString(_ctx.startCase(source)), 1))
        ]))
      }), 128))
    ])
  ]))
}